import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  TextField,
} from '@mui/material';
import { useState } from 'react';

interface IProps {
  onClose: () => void;
  onSave: (customId: string) => void;
  initialCustomId?: string;
}

export const EditCustomIdDialog = ({
  onClose,
  onSave,
  initialCustomId,
}: IProps) => {
  const [customId, setCustomId] = useState(initialCustomId || '');

  return (
    <Dialog open maxWidth='sm' fullWidth onClose={onClose}>
      <DialogTitle>Eigene Kennung bearbeiten</DialogTitle>
      <DialogContent>
        <TextField
          label='Eigene Kennung'
          value={customId}
          fullWidth
          sx={{ mt: 2 }}
          onChange={e => setCustomId(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button variant='contained' onClick={() => onSave(customId)}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};
