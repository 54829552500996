import React from 'react';
import './App.css';
import { Alert, ThemeProvider, createTheme, type Theme } from '@mui/material';
import { deDE as dataGridDeDE } from '@mui/x-data-grid';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from '@mui/x-date-pickers/locales';
import SecurityDriver from './components/core/SecurityDriver';
import { PrivatePageRouter } from './components/core/PrivatePageRouter';
import { useConfig } from './hooks/data';
import { Loader } from './components/generic/Loader';
import { AlertProvider } from './context/AlertContext';

function App() {
  const { config, isLoadingConfig } = useConfig();

  let theme: Theme | undefined = undefined;

  if (config) {
    theme = createTheme(
      {
        palette: {
          primary: {
            main: config.ci_color_primary,
          },
          secondary: {
            main: config.ci_color_secondary,
          },
          text: {
            primary: config.ci_color_text,
          },
        },
      },
      deDE, // x-date-pickers translations
      dataGridDeDE, // x-data-grid translations
      coreDeDE // core translations
    );

    const link = document.createElement('link');

    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = `data:image/x-icon;base64,${config.favicon}`;

    const oldLink = document.getElementById('dynamic-favicon');
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
    document.title = `Mandantenportal - ${config.company_name}`;
  }

  if (isLoadingConfig || !theme) {
    return <Loader />;
  }

  if (!config || (config && !config.App.find(x => x === 'dino_pro'))) {
    return (
      <Alert severity='warning'>
        Die Lizenz von DiNo konnte nicht überprüft werden!
      </Alert>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <SecurityDriver configObject={config}>
          <PrivatePageRouter configObject={config} />
        </SecurityDriver>
      </AlertProvider>
    </ThemeProvider>
  );
}

export default App;
