import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AddTask, Delete, Download, Reply } from '@mui/icons-material';
import { blue, green, grey, red, yellow } from '@mui/material/colors';
import { IDraftMessageFullObject } from '../../../../Interfaces/IDraftMessage';
import { LegalRequirementRequestFiles } from '../LegalPhase/LegalRequirementRequestFiles';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { IConfig } from '../../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  legalTransactionObject: ILegalTransactionFullObject;
  draftMessageObject: IDraftMessageFullObject;
  draftMessageArray: IDraftMessageFullObject[];
  setDraftMessageArray: Function;
  setIdDraftMessageParent: Function;
  enableStateEdit?: boolean;
}

export const ViewDraftMessage: React.FC<IProps> = ({
  configObject,
  legalTransactionObject,
  draftMessageObject,
  draftMessageArray,
  setDraftMessageArray,
  setIdDraftMessageParent,
  enableStateEdit,
}) => {
  const ownIdUser = localStorage.getItem('idUser');
  const ownIdContact = localStorage.getItem('idContact');

  const [isOpenChangeState, setIsOpenChangeState] = useState(false);
  const [editIdDraftMessageState, setEditIdDraftMessageState] = useState(
    draftMessageObject.idDraftMessageState
  );
  const [isOpen, setIsOpen] = useState(false);
  const [idDocument, setIdDocument] = useState<number | undefined>(undefined);
  const floatPaper =
    draftMessageObject.idUser !== null &&
    ownIdUser &&
    draftMessageObject.idUser === Number(ownIdUser)
      ? 'right'
      : draftMessageObject.idContact &&
          ownIdContact &&
          draftMessageObject.idContact === Number(ownIdContact)
        ? 'right'
        : 'left';

  const matchesSmall = useMediaQuery('(min-width:600px)');
  const matchesLarge = useMediaQuery('(min-width:1000px)');
  const matchesExtraLarge = useMediaQuery('(min-width:1500px)');

  const parentObject =
    draftMessageObject.Partent_idDraftMessage === null
      ? undefined
      : draftMessageArray.find(
          x => x.idDraftMessage === draftMessageObject.Partent_idDraftMessage
        );

  const handleDelete = () => {
    setDraftMessageArray([
      ...draftMessageArray.filter(
        x => x.idDraftMessage !== draftMessageObject.idDraftMessage
      ),
    ]);
  };

  const handleOpenDialogStateChange = () => {
    setEditIdDraftMessageState(draftMessageObject.idDraftMessageState);
    setIsOpenChangeState(true);
  };

  const handleSaveChangeState = () => {
    const tmpObject = {
      ...draftMessageObject,
      idDraftMessageState: editIdDraftMessageState,
    };
    setDraftMessageArray([
      ...draftMessageArray.map(x =>
        x.idDraftMessage === tmpObject.idDraftMessage ? tmpObject : x
      ),
    ]);
    setIsOpenChangeState(false);
  };

  const handleDownloadReques = (localIdDocument: number) => {
    setIdDocument(localIdDocument);
    setIsOpen(true);
  };

  const getBcColor = () => {
    return draftMessageObject.idDraftMessageState === 1
      ? blue[50]
      : draftMessageObject.idDraftMessageState === 10
        ? blue[100]
        : draftMessageObject.idDraftMessageState === 20
          ? green[100]
          : draftMessageObject.idDraftMessageState === 30
            ? yellow[50]
            : red[50];
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <LegalRequirementRequestFiles
          configObject={configObject}
          idDocument={idDocument}
          legalTransaction={legalTransactionObject}
          documentArray={draftMessageObject.DocumentArray.filter(
            x => x.idDocument > 0
          )}
          setIsOpen={setIsOpen}
        />
      </Dialog>

      <Dialog
        open={isOpenChangeState}
        onClose={() => setIsOpenChangeState(false)}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle>Status ändern</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 2 }}
            label={'Status'}
            value={editIdDraftMessageState}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEditIdDraftMessageState(Number(event.target.value))
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem key='idDraftMessageState-1' value={1}>
              Neu
            </MenuItem>
            <MenuItem key='idDraftMessageState-10' value={10}>
              In Bearbeitung
            </MenuItem>
            <MenuItem key='idDraftMessageState-20' value={20}>
              Erledigt
            </MenuItem>
            <MenuItem key='idDraftMessageState-30' value={30}>
              Zurückgewiesen
            </MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => setIsOpenChangeState(false)}
          >
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleSaveChangeState}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <Paper
        elevation={3}
        sx={{
          backgroundColor: getBcColor(),
          minWidth: !matchesSmall
            ? 300
            : !matchesLarge
              ? 400
              : !matchesExtraLarge
                ? 700
                : 1200,
          float: floatPaper,
        }}
      >
        <IconButton
          onClick={() =>
            setIdDraftMessageParent(draftMessageObject.idDraftMessage)
          }
          sx={{ float: 'right' }}
          size='small'
        >
          <Reply />
        </IconButton>

        {draftMessageObject.idDraftMessage < 0 && (
          <IconButton
            onClick={handleDelete}
            sx={{ float: 'right' }}
            size='small'
          >
            <Delete />
          </IconButton>
        )}

        {enableStateEdit && (
          <IconButton
            onClick={handleOpenDialogStateChange}
            sx={{ float: 'right' }}
            size='small'
          >
            <AddTask />
          </IconButton>
        )}

        <br />
        <Box sx={{ ml: 2 }}>
          {parentObject !== undefined && (
            <>
              <Paper
                variant='outlined'
                sx={{ m: 2, backgroundColor: grey[50] }}
              >
                <Box sx={{ m: 2 }}>
                  Antwort zu:
                  <br />
                  <i>{parentObject.DraftMessage}</i>
                </Box>
              </Paper>
            </>
          )}

          {draftMessageObject.DraftMessage}

          {draftMessageObject.DocumentArray.length > 0 && (
            <>
              <Card
                variant='outlined'
                sx={{ mt: 5, mr: 2, backgroundColor: getBcColor() }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      Anhänge
                    </Grid>
                    {draftMessageObject.DocumentArray.map(x => (
                      <>
                        <Grid item xs={8} sm={10}>
                          {x.FileName}
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          <IconButton
                            size='small'
                            onClick={() => handleDownloadReques(x.idDocument)}
                          >
                            <Download />
                          </IconButton>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </Box>

        <br />
        <Box sx={{ float: 'right', mr: 2 }}>
          <Typography variant='caption'>
            {floatPaper === 'right' ? (
              <>Von mir</>
            ) : draftMessageObject.idUser === null ? (
              <>
                Von {draftMessageObject.ContactFirstName}{' '}
                {draftMessageObject.ContactLastName} (Mandant/in)
              </>
            ) : (
              <>
                Von {draftMessageObject.UserFirstName}{' '}
                {draftMessageObject.UserLastName} (Mitarbeiter/in)
              </>
            )}
          </Typography>
        </Box>
        <br />

        <Box sx={{ ml: 2, mb: 0 }}>
          {draftMessageObject.idDraftMessageState === 20 ? (
            <Typography variant='caption'>(Erledigt)</Typography>
          ) : (
            draftMessageObject.idDraftMessageState === 30 && (
              <Typography variant='caption'>(Zurückgewiesen)</Typography>
            )
          )}
          <Box sx={{ float: 'right', mr: 2 }}>
            <Typography variant='caption'>
              {new Date(String(draftMessageObject.Created_at)).toLocaleString()}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </>
  );
};
