import React, { useState } from 'react';
import { IConfig } from '../../Interfaces/IConfig';
import { Alert, Box, Button, Grid, Switch, TextField } from '@mui/material';

interface IProps {
  msg: string;
  idContact: number;
  tmpSessionToken: string;
  sendFunction: Function;
  configObject: IConfig;
}

export const LoginF2A: React.FC<IProps> = ({
  msg,
  idContact,
  tmpSessionToken,
  sendFunction,
  configObject,
}) => {
  const [code, setCode] = useState<string>('');
  //
  const [saveDevice, setSaveDevice] = useState(false);

  const handleChangeCode = (input: string) => {
    const regex = /^[0-9]*$/;
    if (regex.test(input)) {
      setCode(input);
    }
  };

  const handleAfterSave = (res: any) => {
    localStorage.setItem('SessionToken', res['SessionToken']);
    localStorage.setItem('TimestampSessionToken', res['TimestampSessionToken']);
    localStorage.setItem('idContact', res['idContact']);
    localStorage.setItem('role', res['Role']);
    localStorage.setItem('scope', res['Scope']);
    localStorage.setItem('passwordResetRequired', res['passwordResetRequired']);

    if ('DeviceToken' in res) {
      localStorage.setItem('DeviceToken', res['DeviceToken']);
    }

    window.location.reload();
  };

  const handleSubmit2FA = (e: React.SyntheticEvent) => {
    e.preventDefault();
    sendFunction(
      {
        idContact: idContact,
        TempSessionToken: tmpSessionToken,
        '2FACode': code,
        SaveDevice: saveDevice,
      } as any,
      handleAfterSave,
      () => {
        setCode('');
      }
    );
  };

  return (
    <form onSubmit={handleSubmit2FA} autoComplete='on'>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <img
          width={250}
          src={`data:${configObject.logo_mime};base64,${configObject.logo}`}
        />
        <Box sx={{ mt: 4 }} />
        <Grid item xs={3} style={{ width: 400 }}>
          <Box sx={{ textAlign: 'center' }}>
            Die Sicherheit Ihrer Daten ist uns sehr wichtig. Damit Ihr Konto
            optimal geschützt ist, verwenden wir eine{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              2-Faktor-Authentifizierung
            </span>
            .
            <br />
            <br />
            Dafür wird ein <b>Authentifizierungsschlüssel</b> per{' '}
            <span style={{ whiteSpace: 'nowrap' }}>E-Mail</span> oder per SMS
            verschickt.
            <br />
            <br />
            Bitte verlassen Sie diese Seite nicht.
            <br /> Ihr Authentifizierungsschlüssel ist eine Stunde lang gültig.
          </Box>
        </Grid>
        <Box sx={{ mt: 6 }} />
        <Grid item xs={3} style={{ minWidth: 350 }}>
          <TextField
            label='Authentifizierungsschlüssel'
            value={code}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeCode(String(event.target.value))
            }
            required
          />
        </Grid>

        <Box sx={{ mt: 3 }} />
        <Grid item xs={3} style={{ width: 350 }}>
          <Alert
            severity='info'
            action={
              <Switch
                checked={saveDevice}
                onChange={() => setSaveDevice(!saveDevice)}
              />
            }
          >
            <b>Gerät speichern</b>
            <br />
            Wollen Sie Ihr aktuelles Gerät speichern?
            <br />
            Gespeicherte Geräte benötigen 30 Tage keine Verifikation durch einen
            Authentifizierungsschlüssel.
          </Alert>
        </Grid>

        <Box sx={{ mt: 3 }} />
        <Grid item xs={3} style={{ minWidth: 300 }}>
          <div style={{ float: 'right' }}>
            <Button
              size='small'
              variant='contained'
              color='primary'
              type='submit'
            >
              Anmelden
            </Button>
          </div>
        </Grid>

        <Box sx={{ mt: 3 }} />
        <Grid item xs={3} style={{ minWidth: 300 }}>
          {msg !== '' && <Alert severity='error'>{msg}</Alert>}
        </Grid>
      </Grid>
    </form>
  );
};
