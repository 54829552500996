import React, { useEffect, useState } from 'react';
import { IConfig } from '../../Interfaces/IConfig';
import { CopyrightLextorByte } from '../../CopyrightLextorByte';
import { LoginDriver } from '../Login/LoginDriver';
import { LoginData } from '../Login/LoginData';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Loader } from '../generic/Loader';

interface IProps {
  children: React.ReactElement;
  configObject: IConfig;
}

const SecurityDriver: React.FC<IProps> = ({ children, configObject }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const [msg, setMsg] = useState<string | null>(null);

  useEffect(() => {
    const sessionToken = localStorage.getItem('SessionToken');

    if (!sessionToken) {
      setIsLogged(false);
      setIsLoading(false);
    } else {
      fetch('/api/oauth', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SessionToken'),
        },
      })
        .then(res => {
          if (res.status == 200) {
            return res.json();
          } else {
            throw Error(String(res.status));
          }
        })
        .then(res => {
          localStorage.setItem(
            'TimestampSessionToken',
            res['TimestampSessionToken']
          );
          setIsLogged(true);
          setIsLoading(false);
        })
        .catch(error => {
          if (String(error).includes('401')) {
            setMsg('Ihre Session ist abgelaufen.');
          } else if (String(error).includes('408')) {
            setMsg(
              'Sie wurden aufgrund von Inaktivität automatisch abgemeldet.'
            );
          } else if (String(error).includes('404')) {
            setMsg(
              'Die Anmeldedaten konnten nicht gefunden werden. Bitte überprüfen Sie den Anmeldename und das Passwort'
            );
          } else if (String(error).includes('403')) {
            setMsg(`Ihr Konto wurde deaktiviert!`);
          } else {
            setMsg(
              `Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`
            );
          }
          setIsLogged(false);
          setIsLoading(false);
        });
    }
  }, []);

  const getTsxBodyLogin = (enableAutoLogin: boolean) => {
    return (
      <>
        <LoginDriver
          enableAutoLogin={enableAutoLogin}
          configObject={configObject}
          msgParent={msg}
        />
        <CopyrightLextorByte configObject={configObject} />
      </>
    );
  };

  if (isLoading) {
    return <Loader />;
  } else if (
    isLogged &&
    localStorage.getItem('passwordResetRequired') !== 'true'
  ) {
    return children;
  } else if (
    isLogged &&
    localStorage.getItem('passwordResetRequired') === 'true'
  ) {
    return <LoginData configObject={configObject} />;
  } else {
    return (
      <>
        <Router>
          <Routes>
            <Route
              path='/auto_login/:email/:emailProvider/:pw'
              element={getTsxBodyLogin(true)}
            />
            <Route index element={getTsxBodyLogin(false)} />
            <Route path='/*' element={getTsxBodyLogin(false)} />
          </Routes>
        </Router>
      </>
    );
  }
};
export default SecurityDriver;
