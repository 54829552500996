import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

type AlertContextType = {
  showAlert: (options: AlertOptions) => void;
  closeAlert: () => void;
};

type AlertOptions = {
  text: string;
  severity?: AlertColor;
  duration?: number;
};

type AlertState = {
  open: boolean;
  text: string;
  severity: AlertColor;
  duration: number;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

type AlertProviderProps = {
  children: ReactNode;
};

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<AlertState>({
    open: false,
    text: '',
    severity: 'success',
    duration: 2000,
  });

  const showAlert = useCallback(
    ({ severity = 'success', text, duration = 2000 }: AlertOptions) => {
      setAlert({ open: true, text, severity, duration });
    },
    []
  );

  const closeAlert = useCallback(() => {
    setAlert(prev => ({ ...prev, open: false }));
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert }}>
      {children}
      <Snackbar
        sx={{ mt: 10 }}
        open={alert.open}
        autoHideDuration={alert.duration}
        onClose={closeAlert}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={closeAlert}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export const useAlert = (): AlertContextType => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};
