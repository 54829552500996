import {
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  Button,
  DialogActions,
  TextField,
} from '@mui/material';
import { useState } from 'react';

interface IProps {
  onClose: () => void;
  onSave: (customId: string) => void;
}

export const AddCustomIdDialog = ({ onClose, onSave }: IProps) => {
  const [customId, setCustomId] = useState('');

  return (
    <Dialog open maxWidth='sm' fullWidth onClose={onClose}>
      <DialogTitle>Eigene Kennung hinzufügen</DialogTitle>
      <DialogContent>
        <Alert severity='info'>
          Sofern Sie eigene Kennungen hinterlegen, werden diese vom System
          anstelle der Transaktions- oder Urkundennummer verwendet, um Ihnen
          eine individuelle und reibungslose Zuordnung zu ermöglichen.
        </Alert>

        <TextField
          label='Eigene Kennung'
          value={customId}
          fullWidth
          sx={{ mt: 2 }}
          onChange={e => setCustomId(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button variant='contained' onClick={() => onSave(customId)}>
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
